import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Oleksandr Khomin</h2>
        <p><a href="mailto:oleksandr@khomin.tech">oleksandr@khomin.tech</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>
        Hi, I&apos;m Oleksandr. I am a Software Engineer, <a href="https://www.antler.co/" target="_blank" rel="noreferrer">Antler</a> Alumni and startup founder.
        I was the co-founder and CTO of <a href="https://coaty.world" target="_blank" rel="noreferrer">Coaty World</a>.
        Worked at <a href="https://sigma.software" target="_blank" rel="noreferrer">Sigma Software</a> and freelanced at <a href="https://www.upwork.com/freelancers/~0131ab6482728b0329" target="_blank" rel="noreferrer">Upwork</a>.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Oleksandr Khomin <Link to="/">khomin.tech</Link>.</p>
    </section>
  </section>
);

export default SideBar;
